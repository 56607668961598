/**
 * Defaults
 */

$red: rgb(255, 0, 0);
$gray-600: rgb(153, 153, 153);
$gray-700: #5e6367 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: rgb(0, 0, 0);

/**
 * Bootstrap
 */

$primary: $red;
$font-family-base: Arial, sans-serif;
//$font-family-base: 'Source Sans Pro', sans-serif;

$navbar-padding-y: 1rem;
$link-color: $gray-600;
$body-color: $gray-800;
$headings-color: $gray-800;
$headings-font-weight: bold;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 7),
);

//
//$grid-breakpoints: (
//    xs: 0,
//    sm: 576px,
//    md: 768px,
//    lg: 992px,
//    xl: 1200px
//);
//
//$container-max-widths: (
//    sm: 540px,
//    md: 720px,
//    lg: 960px,
//    xl: 1140px
//);
//

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1012px,
    xl: 1440px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 980px,
    xl: 1400px
);

/**
 * Bootstrap
 */

@import "vendor/bootstrap/bootstrap";

/**
 * MAIN
 */

body {
    padding-top: 6.25rem;
}

h1, .h1 {
    font-size: 2.6rem;
    font-weight: $font-weight-normal;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

h2, .h2 {
    margin-top: 1rem;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
}

h3, .h3 {
    margin-top: 1rem;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
}

h4, .h4, h5, .h5 {
    margin-top: 1.5rem;
}

.container {
    min-width: 300px;
}

/**
 * Header
 */

header {

    .container {
        position: relative;
    }

    .navbar {
        padding-top: 2.8rem;
        padding-bottom: .8rem;
    }

    .navbar > .container, .navbar > .container-fluid {
        align-items: initial;
    }

    .navbar-brand-right {
        position: absolute;
        right: 0;
        top: 0.7rem;
    }

    .navbar-brand {

        height: 1.2rem;
        padding-top: 0;
        padding-bottom: 0;

        @include media-breakpoint-down(xs) {
            height: 1rem;
        }

        img {
            height: 100%;
            width: auto;
            vertical-align: top;
        }

        .logo-font {
            color: $red;
            font-weight: bold;
            letter-spacing: 0.12em;
            font-size: 1.1em;
        }
    }

    .navbar-nav {
        @include media-breakpoint-down(md) {
            padding-top: 1rem;
        }
        font-size: 1.1em;

        .nav-item:first-child {
            .nav-link {
                padding-left: 0;
            }
        }
    }

    .navbar-toggler {
        margin: 0.25rem 0;
    }

    .navbar-toggler-icon {
        width: 1.2rem;
        height: 1.3rem;
    }

    .navbar-mobile-shown {
        box-shadow: none;
        transition: box-shadow 0.5s;
    }

    @include media-breakpoint-down(md) {
        .navbar-mobile-shown {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        }
    }

    @include media-breakpoint-up(sm) {
        .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
            padding-right: ($grid-gutter-width / 2);
            padding-left: ($grid-gutter-width / 2);
        }
    }
}

/**
 * Main
 */

main {
    img {
        width: 100%;
        height: auto;
    }

    .header-line {
        padding: 0.5rem;
        background-color: $gray-200;
    }

    nav {
        ul {
            list-style: none;
            padding-left: 0;
        }
    }

    li {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

}

/**
 * Footer
 */

footer {
    padding: 2rem 0;
}

/**
 * Swiper
 */

.swiper-container {
    img, figure {
        width: 100%;
        height: auto;
        margin: 0;
    }
    .swiper-button-white {
        filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.45));
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 0;
        width: 30%;
        height: 100%;
        z-index: 10;
        cursor: pointer;
        background-size: 65px 75px;
        background-repeat: no-repeat;
        outline: none;
        &:focus {
            outline: none;
        }
    }

    .swiper-button-prev {
        left: 0;
        background-position: center left;
    }
    .swiper-button-next {
        right: 0;
        background-position: center right;
    }
    .swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
        background-image: url("../Images/arrow-left.svg");
    }
    .swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
        background-image: url("../Images/arrow-right.svg");
    }

    .swiper-caption-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        display: flex;

        color: $white;
        border-bottom: $white 2px solid;

        &::after {
            content: "";
            display: block;
            background-color: white;
            height: auto;
            flex: 1 1 auto;
        }

        .swiper-caption {
            flex: 0 0 auto;
            padding: 0 0.3rem;
            max-width: 100%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

    }

    .invert-caption {
        .swiper-caption-container {
            color: $black;
        }
    }
}

.swiper-container-backend {
    .swiper-wrapper {
        display: block;
    }

    .swiper-caption-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        color: $white;
        border-bottom: $white 2px solid;

        .swiper-caption {
            padding: 0 0.3rem;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .invert-caption {
        .swiper-caption-container {
            color: $black;
        }
    }

}

/**
 * Elements
 */

.description-list {
    margin-bottom: 1rem;

    p:last-child {
        margin-bottom: 0;
    }

    &-data {
        @include media-breakpoint-down(xs) {
            margin-bottom: 1rem;
        }
    }
}


.category-menu {
    .project {
        margin-bottom: 0;
    }
}


/**
 * Inserttages
 */
.inserttag-image {
    width: auto;

    &.inserttag-height-1 {
        height: 1rem;
    }
    &.inserttag-height-2 {
        height: 2rem;
    }
    &.inserttag-height-3 {
        height: 3rem;
    }
}


/**
 * Others
 */

.text-red {
    color: $red !important;
    a {
        color: $red !important;
        &:hover {
            color: darken($red, 10%) !important;
        }
    }
}

.text-gray {
    color: $gray-600 !important;
}

.crop-arrow-down {

    &:after {
        position: relative;
        content: "";
        display: block;
        width: 100%;
        height: 20px;
        margin-top: -18px;
        background-repeat: repeat-x;
        background-position: top center;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1000' height='20'><polygon fill='white' points='0,0 470,0 500,15 530,0 1000,0 1000,50 0,50'/><polygon fill='white' points='0,0 1000,0 1000,2 0,2'/></svg>");
        pointer-events: none;
    }
}

.container-full {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.container-full-not-cropped {
    &-inner {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.navbar-toggler {
    margin: 0;
    padding: 0.2rem 0.4rem;
}

.scroll-down-self {
    width: 100%;
}

