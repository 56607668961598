/**
 * Fonts
 */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700');

/**
 * Font awesome
 */
$fa-font-path: "../Fonts/fontawesome";
@import "vendor/font-awesome/fontawesome";
@import "vendor/font-awesome/regular";
//@import "vendor/font-awesome/brands";
